import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Intro from '../components/Intro';
import Categories from '../components/Categories';

export default function IndexPage() {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: {eq: "categories"} }) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                order
                title
                description
                products
              }
            }
          }
        }
      }
    }
  `);

  const categories = allFile.edges;

  return (
    <Fragment>
      <Intro title="Hand selected products for the smallest member of your pack." />
      <Categories categories={categories} />
    </Fragment>
  );
}
