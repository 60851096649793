import React from 'react';
import PropTypes from 'prop-types';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import useAnimateOnScroll from '../../utils/useAnimateOnScroll';

import './index.scss';

export default function Product({ node }) {
  useAnimateOnScroll();

  const name = node.childMarkdownRemark.frontmatter.name;

  const onClick = () => {
    trackCustomEvent({
      category: 'Product',
      action: 'click',
      label: name,
    });
  };

  return (
    <div className="Product" data-aos="slide-up">
      <a
        className="Product__link"
        href={node.childMarkdownRemark.frontmatter.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        <figure className="Product__img">
          <img src={node.childMarkdownRemark.frontmatter.image} alt={name} />
        </figure>
        <h3>{name}</h3>
        <p className="Product__text">{node.childMarkdownRemark.frontmatter.description}</p>
        <span className="Product__view">View</span>
      </a>
    </div>
  );
}

Product.propTypes = {
  node: PropTypes.object.isRequired,
};
