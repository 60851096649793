import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Product from '../Product';
import slugify from 'slugify';

import './index.scss';

export default function Category({ title, description, products, svg }) {
  if (!products.length) return null;

  const displayedProducts = products.slice(0, 3).map(({ node }) => (
    <Product
      key={node.id}
      node={node}
    />
  ));

  return (
    <section
      className="Category section-padding"
      id={slugify(title, { lower: true })}
    >
      <div className="grid">
        {svg}
        <header className="Category__header">
          <h2 className="Category__title">{title}</h2>
          <p className="Category__text">{description}</p>
        </header>
        <div className="Category__container">
          {displayedProducts}
        </div>
        <div className="Category__pagination">
          <Link className="Button" to={`/${slugify(title, { lower: true })}`}>
            <span>View more</span>
          </Link>
        </div>
      </div>
    </section>
  );
}

Category.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  svg: PropTypes.element,
};

Category.defaultProps = {
  svg: null,
}
