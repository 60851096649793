import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Category from '../Category';
import Arf from '../../assets/arf.svg';
import Awoo from '../../assets/awoo.svg';
import Circle from '../../assets/circle.svg';
import Kiss from '../../assets/kiss.svg';
import Paws from '../../assets/paws.svg';
import Woof from '../../assets/woof.svg';

import './index.scss';

export default function Categories({ categories }) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: {eq: "products"} }) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                description
                name
                link
                image
              }
            }
          }
        }
      }
    }
  `);

  const products = allFile.edges;

  const sortedCategories = categories.sort((a, b) => {
    return a.node.childMarkdownRemark.frontmatter.order - b.node.childMarkdownRemark.frontmatter.order;
  });

  const allCategories = sortedCategories.map((category, idx) => {
    const categoryProducts = category.node.childMarkdownRemark.frontmatter.products;
    const filteredProducts = [];

    if (categoryProducts) {
      for (let i = 0; i < categoryProducts.length; i++) {
        for (let j = 0; j < products.length; j++) {
          if (categoryProducts[i] === products[j].node.childMarkdownRemark.frontmatter.name) {
            filteredProducts.push(products[j]);
          }
        }
      }
    }

    let svg;

    switch (idx) {
      case 1:
        svg = <Woof className="Category__svg Category__svg--left" />;
        break;
      case 2:
        svg = <Arf className="Category__svg Category__svg--right" />;
        break;
      case 3:
        svg = <Awoo className="Category__svg Category__svg--left" />;
        break;
      case 4:
        svg = <Kiss className="Category__svg Category__svg--right" />;
        break;
      default:
        svg = null;
    }

    return (
      <Category
        key={category.node.id}
        title={category.node.childMarkdownRemark.frontmatter.title}
        description={category.node.childMarkdownRemark.frontmatter.description}
        products={filteredProducts}
        svg={svg}
      />
    );
  });

  return (
    <div className="Categories" id="categories">
      <div className="Categories__svg">
        <Circle className="Categories__circle" />
        <Paws className="Categories__paws" />
      </div>

      {allCategories}
    </div>
  );
}

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
};
